import * as React from "react"
import Header from "../elements/Header"
import Footer from "../elements/Footer"
import Newsletter from "../elements/Newsletter"
import { graphql } from "gatsby"
import { SEO } from "../components/SeoNew"
import Navigation from "../components/Navigation"
import { Container } from "../components/Container"
import { PageContainer } from "../components/PageContainer"
import { useEffect } from "react"

const AboutUsPage = ({ data}) => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [data])
  return(
  <>
    <PageContainer>
      <Navigation />
      <Container className="mt-16 mb-16">
        <h1
          className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
          About Us
        </h1>
        <div className="prose prose-blue w-full max-w-full prose-lg text-gray-500 mx-auto mt-8">
          <p><strong>ndna.io</strong> was founded by <strong>Hubert Rządziński</strong> in 2022 after numerous
            successes with application development and IT support for multiple befriended companies. All this led
            to the expansion of the business to create a team that will better meet the needs of customers.</p>
          <p>
            Our team specializes in delivering high-quality software solutions that are tailored to your business
            needs. We prioritize quality, timeliness, and a deep understanding of your unique business cases. Our
            goal is to help your business thrive by leveraging technology to improve your operations and customer
            experience.
          </p>
          <h2>What distinguishes us from the competitors?</h2>
          <ul>
            <li>Well-proven application development tools that allow us to get results quickly</li>
            <li>Open application development process, you will be kept informed about the progress</li>
            <li>Free support in case of problems after project completion</li>
            <li>Prioritizing cybersecurity - we set ourselves apart from competitors who may not place the same
              level of importance on security
            </li>
          </ul>
          <h2>Our specialities</h2>
          <p>At our software house, we have a talented team of developers who specialize in multiple technologies
            and are committed to continually improving their skills. We believe in staying up-to-date with the
            latest technologies and methodologies to ensure that we provide our clients with the best possible
            solutions.</p>
          <div className="mt-2">
                      <span
                        className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">Javascript</span>
            <span
              className="bg-emerald-100 text-emerald-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-900">Vue</span>
            <span
              className="bg-blue-900 text-blue-300 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">PHP</span>
            <br />
            <span
              className="bg-orange-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">Java</span>
            <span
              className="bg-sky-600 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">React</span>
            <span
              className="bg-sky-300 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">Flutter</span>
            <br />
          </div>
          <h2>Company Culture</h2>
          <p>Each of our developers works remotely, our company does not have an office. We value independence and
            the ability to work from anywhere on earth.
            {/*Our team consists of developers from Poland and United Kingdom.*/}
          </p>
          {/*<p>*/}
          {/*  Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec*/}
          {/*  commodo sit viverra aliquam*/}
          {/*  porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl,*/}
          {/*  justo, amet, mattis. Nunc*/}
          {/*  purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.*/}
          {/*</p>*/}
          {/*<h2>From beginner to expert in 30 days</h2>*/}
          {/*<p>*/}
          {/*  Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis*/}
          {/*  arcu ipsum urna nibh.*/}
          {/*  Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas*/}
          {/*  pellentesque id sed tellus*/}
          {/*  mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed*/}
          {/*  nullam sed diam turpis*/}
          {/*  ipsum eu a sed convallis diam.*/}
          {/*</p>*/}
          {/*<blockquote>*/}
          {/*  <p>*/}
          {/*    Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed*/}
          {/*    consectetur neque tristique*/}
          {/*    pellentesque. Blandit amet, sed aenean erat arcu morbi.*/}
          {/*  </p>*/}
          {/*</blockquote>*/}
          {/*<p>*/}
          {/*  Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis*/}
          {/*  mauris semper sed amet vitae*/}
          {/*  sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus*/}
          {/*  varius sit neque erat velit.*/}
          {/*</p>*/}
          {/*<figure>*/}
          {/*  <img*/}
          {/*    className="w-full rounded-lg"*/}
          {/*    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"*/}
          {/*    alt=""*/}
          {/*    width={1310}*/}
          {/*    height={873}*/}
          {/*  />*/}
          {/*  <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.*/}
          {/*  </figcaption>*/}
          {/*</figure>*/}
          {/*<h2>Everything you need to get up and running</h2>*/}
          {/*<p>*/}
          {/*  Purus morbi dignissim senectus mattis <a href="#">adipiscing</a>. Amet, massa quam*/}
          {/*  varius orci dapibus*/}
          {/*  volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed*/}
          {/*  tempus ut viverra ridiculus*/}
          {/*  non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis*/}
          {/*  auctor venenatis varius nunc,*/}
          {/*  congue erat ac. Cras fermentum convallis quam.*/}
          {/*</p>*/}
          {/*<p>*/}
          {/*  Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis*/}
          {/*  mauris semper sed amet vitae*/}
          {/*  sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus*/}
          {/*  varius sit neque erat velit.*/}
          {/*</p>*/}
        </div>
      </Container>
    </PageContainer>
    <Footer />
  </>
)
}

export default AboutUsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export const Head = () => <>
  <SEO title="About Us" />
</>
